$c-black: #000000;
$c-white: #ffffff;

$c-gray: #d5d5d5;
$c-gray-text: #c6c6c6;
$c-gray-dark: #3d3d3d;
$c-gray-dark-light: #555555;
$c-blue: #158fff;
$c-blue-shadow: #0f79d9;

$c-red: #ff6666;
$c-yellow: #f9cd38;
$c-green: #4aff4a;
