body {
  background-color: $c-black;
  color: $c-white;
}

.neural-numbers-component {
  @include defaultFont;

  .input-canvas-wrapper {
    border: 4px dashed $c-gray;
  }

  .drawcanvas {
    filter: none;
  }

  .stage-input.background {
    .input-placeholder {
      color: $c-white;
      opacity: 0.85;
    }
  }

  .output-wrapper {
    .output {
      color: $c-white;
    }
  }

  .bars {
    .bar {
      border-color: black;
      background: linear-gradient(90deg, $c-blue-shadow, $c-blue);
    }
  }
}

.neural-numbers-training-component {
  background-color: transparent;

  .btn {
    border: none;
  }
}
