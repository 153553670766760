#neural-numbers-container {
  transition: margin-top 0.4s ease-in-out;
}

#training-panel {
  border-radius: 1.5rem 1.5rem 0 0;
  background-color: $c-gray-dark-light;

  transition: margin-bottom 0.3s ease-in-out;
}

#training-controls {
  background-color: $c-gray-dark;
  padding: 0.5rem 1.2rem 1.5rem;
  margin: 0rem -1.2rem -1.5rem;
}

#training-mode-button {
  border: none;
  background-color: transparent;
  display: block;
  width: 100%;
  box-sizing: content-box;
  padding: 1rem 1rem 0 1rem;
  margin: -1rem -1rem 0 -1rem;
}

#training-panel-separator {
  height: 0;
}

.training-title {
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: white;
  text-align: left;

  .en {
    font-size: 1.4rem;
    font-weight: normal;
  }

  &:after {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    right: 1rem;
    width: 1.5em;
    height: 2em;
    background-image: url('../../static/fa/chevron-down-solid.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

#training-panel.closed {
  .training-title:after {
    transform: rotateX(180deg);
  }
}

.neural-numbers-component {
  .stage-input {
    margin-right: 1rem;
  }

  .input-canvas-wrapper {
    border-width: 8px !important;
  }

  .input-placeholder {
    line-height: 1.15;
    color: $c-gray-text !important;

    .placeholder-i18n.de {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 0.8em;
    }

    .placeholder-i18n.en {
      font-size: 1.5rem;
      margin-top: 0.9rem;
    }
  }

  .stage-bars {
    margin-right: 1rem;
  }

  .stage-output {

  }

  @keyframes IAmSmart {
    // Animate the radial-gradient's circle position
    0% {
      background-position: 0 0;
    }
    25% {
      background-position: 100% 0;
    }
    50% {
      background-position: 100% 100%;
    }
    75% {
      background-position: 0 100%;
    }
  }

  .output-wrapper {
    border-radius: 1rem;
    background: radial-gradient(circle at 50% 50%, rgba(16,130,232,.7) 0,rgba(16,130,232,0.3) 250px);
    background-size: 400px 400px;
    animation: IAmSmart 6.5s infinite;

    &:before {
      border-radius: 1rem;
      background-color: #092846;
    }
  }
}

.neural-numbers-training-component {
  @include defaultFont;

  .image-count-label,
  .accuracy-label {
    font-size: 1.1rem;
    text-align: left;
    margin-right: 0.75rem;

    .en {
      font-weight: normal;
    }
  }

  .image-count-value {
    margin-top: -0.25rem;
  }

  .accuracy-value {
    padding: 0;
    margin-top: -0.25rem;
    text-align: left;
    color: $c-green;

    &[data-ranking='mediocre'] {
      color: $c-yellow;
    }

    &[data-ranking='bad'] {
      color: $c-red;
    }
  }
}

.bars {
  height: auto !important;

  .bartxt {
    font-size: 1.5rem;
    margin-right: 0.2rem;
    margin-top: 0;
  }

  .barcontainer {
    margin-bottom: 0.4rem;
  }

  .bar {
    height: 1.5rem;
  }
}


