html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  @include defaultFont;
  user-select: none;
}

.no-transition {
  transition: none !important;
}
