.fatal-error {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(92, 6, 6, 1);
  background-image: url('../../static/fa/exclamation-triangle-solid.svg');
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-position: center 50px;
  padding-top: 160px;

  .fatal-error-text {
    color: white;
    font-size: 20px;
    text-align: center;
    top: 100px;
    left: 50%;
    width: 600px;
    margin: auto;
    margin-bottom: 0.5em;
    padding-left: 15px;
    padding-right: 15px;
  }

  .fatal-error-details {
    color: white;
    font-size: 16px;
    text-align: center;
    width: 600px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .fatal-error-stack {
    text-align: left;
    background-color: rgba(255, 255, 255, 0.5);
    font-family: monospace;
    font-size: 12px;
    margin: 2em auto 0;
    width: 800px;
    padding: 2em;
  }
}

html.with-fatal-error {
  width: 100%;
  height: 100%;

  body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
