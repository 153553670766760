html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
  user-select: none;
}

.no-transition {
  transition: none !important;
}

.neural-numbers-component {
  height: auto;
  margin-top: 100px;
}
.neural-numbers-component .input-canvas-wrapper {
  width: 350px;
  height: 350px;
}
.neural-numbers-component .drawcanvas {
  height: 350px;
  width: 350px;
}
.neural-numbers-component .input-placeholder {
  width: 350px;
  height: 350px;
}
.neural-numbers-component .input-placeholder span {
  display: block;
  width: 100%;
}
.neural-numbers-component .input-placeholder .placeholder-i18n {
  display: block;
}

#training-panel {
  position: fixed;
  bottom: 0;
  padding: 1rem 1.25rem 1.5rem;
  height: auto;
  width: 90%;
  margin: 0 5%;
}

.neural-numbers-training-component {
  justify-content: space-between;
}
.neural-numbers-training-component .start-pause-btn {
  width: 6rem;
  height: 6rem;
  margin-right: 0.5rem;
}
.neural-numbers-training-component .reset-btn,
.neural-numbers-training-component .step-btn {
  width: 4rem;
  height: 4rem;
}

.fatal-error {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(92, 6, 6);
  background-image: url("../../static/fa/exclamation-triangle-solid.svg");
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-position: center 50px;
  padding-top: 160px;
}
.fatal-error .fatal-error-text {
  color: white;
  font-size: 20px;
  text-align: center;
  top: 100px;
  left: 50%;
  width: 600px;
  margin: auto;
  margin-bottom: 0.5em;
  padding-left: 15px;
  padding-right: 15px;
}
.fatal-error .fatal-error-details {
  color: white;
  font-size: 16px;
  text-align: center;
  width: 600px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.fatal-error .fatal-error-stack {
  text-align: left;
  background-color: rgba(255, 255, 255, 0.5);
  font-family: monospace;
  font-size: 12px;
  margin: 2em auto 0;
  width: 800px;
  padding: 2em;
}

html.with-fatal-error {
  width: 100%;
  height: 100%;
}
html.with-fatal-error body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#neural-numbers-container {
  transition: margin-top 0.4s ease-in-out;
}

#training-panel {
  border-radius: 1.5rem 1.5rem 0 0;
  background-color: #555555;
  transition: margin-bottom 0.3s ease-in-out;
}

#training-controls {
  background-color: #3d3d3d;
  padding: 0.5rem 1.2rem 1.5rem;
  margin: 0rem -1.2rem -1.5rem;
}

#training-mode-button {
  border: none;
  background-color: transparent;
  display: block;
  width: 100%;
  box-sizing: content-box;
  padding: 1rem 1rem 0 1rem;
  margin: -1rem -1rem 0 -1rem;
}

#training-panel-separator {
  height: 0;
}

.training-title {
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: white;
  text-align: left;
}
.training-title .en {
  font-size: 1.4rem;
  font-weight: normal;
}
.training-title:after {
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  right: 1rem;
  width: 1.5em;
  height: 2em;
  background-image: url("../../static/fa/chevron-down-solid.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#training-panel.closed .training-title:after {
  transform: rotateX(180deg);
}

.neural-numbers-component .stage-input {
  margin-right: 1rem;
}
.neural-numbers-component .input-canvas-wrapper {
  border-width: 8px !important;
}
.neural-numbers-component .input-placeholder {
  line-height: 1.15;
  color: #c6c6c6 !important;
}
.neural-numbers-component .input-placeholder .placeholder-i18n.de {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.8em;
}
.neural-numbers-component .input-placeholder .placeholder-i18n.en {
  font-size: 1.5rem;
  margin-top: 0.9rem;
}
.neural-numbers-component .stage-bars {
  margin-right: 1rem;
}
@keyframes IAmSmart {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 100% 0;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0 100%;
  }
}
.neural-numbers-component .output-wrapper {
  border-radius: 1rem;
  background: radial-gradient(circle at 50% 50%, rgba(16, 130, 232, 0.7) 0, rgba(16, 130, 232, 0.3) 250px);
  background-size: 400px 400px;
  animation: IAmSmart 6.5s infinite;
}
.neural-numbers-component .output-wrapper:before {
  border-radius: 1rem;
  background-color: #092846;
}

.neural-numbers-training-component {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.neural-numbers-training-component .image-count-label,
.neural-numbers-training-component .accuracy-label {
  font-size: 1.1rem;
  text-align: left;
  margin-right: 0.75rem;
}
.neural-numbers-training-component .image-count-label .en,
.neural-numbers-training-component .accuracy-label .en {
  font-weight: normal;
}
.neural-numbers-training-component .image-count-value {
  margin-top: -0.25rem;
}
.neural-numbers-training-component .accuracy-value {
  padding: 0;
  margin-top: -0.25rem;
  text-align: left;
  color: #4aff4a;
}
.neural-numbers-training-component .accuracy-value[data-ranking=mediocre] {
  color: #f9cd38;
}
.neural-numbers-training-component .accuracy-value[data-ranking=bad] {
  color: #ff6666;
}

.bars {
  height: auto !important;
}
.bars .bartxt {
  font-size: 1.5rem;
  margin-right: 0.2rem;
  margin-top: 0;
}
.bars .barcontainer {
  margin-bottom: 0.4rem;
}
.bars .bar {
  height: 1.5rem;
}

body {
  background-color: #000000;
  color: #ffffff;
}

.neural-numbers-component {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.neural-numbers-component .input-canvas-wrapper {
  border: 4px dashed #d5d5d5;
}
.neural-numbers-component .drawcanvas {
  filter: none;
}
.neural-numbers-component .stage-input.background .input-placeholder {
  color: #ffffff;
  opacity: 0.85;
}
.neural-numbers-component .output-wrapper .output {
  color: #ffffff;
}
.neural-numbers-component .bars .bar {
  border-color: black;
  background: linear-gradient(90deg, #0f79d9, #158fff);
}

.neural-numbers-training-component {
  background-color: transparent;
}
.neural-numbers-training-component .btn {
  border: none;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.loader {
  display: flex;
  height: 100%;
  justify-content: center;
}
.loader .lds-ellipsis {
  align-self: center;
  transform: scale(1.5);
}

.loader-hide {
  visibility: hidden;
}

body.loaded .loader {
  display: none;
}
body.loaded .loader-hide {
  visibility: visible;
}